import {
  Affiliation,
  AssetStatus,
  Bill,
  Household,
  Member,
  Policy,
  PolicyAddress,
  ProductType,
  VehicleType,
} from "@hagerty/self-service-components/api/types";
import {
  Alerts,
  Customer,
  DriversClub,
  FeatureFlags,
  InsuranceWithBilling,
} from ".";
import {
  ApexApiBill,
  BillCategory,
  BillStatus,
  BillType,
  CurrencyCode,
} from "@hagerty/self-service-components";

export type PolicyManagementDetails = {
  [key: string]: Policy;
} | null;

export type Persona = {
  /** Unique key used for DevTools */
  key: string;
  description: string;
  customer: Customer;
  alerts: Alerts;
  contactId: string;
  coreBills: Bill[];
  apexBills: ApexApiBill[];
  insuranceWithBilling: InsuranceWithBilling;
  hdc: DriversClub;
  featureFlags: Partial<FeatureFlags>;
  policyManagementDetails: PolicyManagementDetails;
};

const hdcPastDueBill: Bill = {
  currency: CurrencyCode.Usd,
  expirationDate: "",
  informAboutStatus: false,
  paymentUrl: "",
  status: BillStatus.PastDue,
  total: {
    formattedAmount: "$100",
  },
  type: BillType.PayOnce,
  productNumber: "1",
  category: BillCategory.Hdc1Dot5,
  id: "1",
  formattedAmount: "$100",
  nextPaymentDate: "2021-05-01",
  productDetails: ["Membership Level 1st gear"],
  revalOffer: null,
};

const hdcAutopayFailedBill: Bill = {
  currency: CurrencyCode.Usd,
  expirationDate: "",
  informAboutStatus: true,
  paymentUrl: "",
  status: BillStatus.Failed,
  total: {
    formattedAmount: "$100",
  },
  type: BillType.AutoPay,
  productNumber: "1",
  category: BillCategory.Hdc1Dot5,
  id: "1",
  formattedAmount: "$100",
  nextPaymentDate: "2021-05-01",
  productDetails: ["Membership Level 1st gear"],
  revalOffer: null,
};

const autopayCoreBill: Bill = {
  productNumber: "2",
  category: BillCategory.Insurance,
  id: "2",
  formattedAmount: "$5,000",
  type: BillType.AutoPay,
  autoPayMethodDetails: {
    last4: "1234",
  },
  expirationDate: "",
  nextPaymentDate: "2099-01-01",
  currency: CurrencyCode.Usd,
  paymentUrl: "",
  informAboutStatus: false,
  status: BillStatus.Ok,
  total: {
    formattedAmount: "$5,000",
  },
  productDetails: ["1956 Porsche Cayenne", "1944 Ford Model T"],
  revalOffer: null,
};

const autopayFailedCoreBill: Bill = {
  productNumber: "3",
  category: BillCategory.Insurance,
  id: "3",
  formattedAmount: "$5,000",
  type: BillType.AutoPay,
  expirationDate: "2000-01-01",
  nextPaymentDate: "2000-01-01",
  autoPayMethodDetails: {
    last4: "1234",
  },
  currency: CurrencyCode.Usd,
  paymentUrl: "",
  informAboutStatus: true,
  status: BillStatus.Failed,
  total: {
    formattedAmount: "$5,000",
  },
  productDetails: ["1956 Porsche Cayenne", "1944 Ford Model T"],
  isSubmitted: false,
  revalOffer: null,
};

const pastDueCoreBill: Bill = {
  productNumber: "4",
  category: BillCategory.Insurance,
  id: "4",
  formattedAmount: "$700",
  type: BillType.PayOnce,
  expirationDate: "",
  nextPaymentDate: "2000-01-01",
  currency: CurrencyCode.Usd,
  paymentUrl: "",
  informAboutStatus: true,
  status: BillStatus.PastDue,
  total: {
    formattedAmount: "$700",
  },
  productDetails: ["1956 Porsche Cayenne", "1944 Ford Model T"],
  isSubmitted: false,
  revalOffer: {
    // Check when test accounts will exist if currentPremium is formatted or not
    currentPremium: "700",
    endoNumber: "123123123",
    endoStatus: "UNKN",
    newPremium: "900",
    policyNumber: "id123",
    premiumDifference: "200",
    vehicleValues: [],
  },
};

const paidCoreBill: Bill = {
  productNumber: "5",
  category: BillCategory.Insurance,
  id: "5",
  formattedAmount: "$12,000",
  type: BillType.PayOnce,
  expirationDate: "2000-01-01",
  nextPaymentDate: "2000-0-01",
  currency: CurrencyCode.Usd,
  paymentUrl: "",
  informAboutStatus: false,
  status: BillStatus.Ok,
  total: {
    formattedAmount: "$12,000",
  },
  productDetails: ["1956 Porsche Cayenne", "1944 Ford Model T"],
  isSubmitted: true,
};

export const coreBills: Bill[] = [
  autopayCoreBill,
  autopayFailedCoreBill,
  pastDueCoreBill,
  paidCoreBill,
];

const apexAutopayBill: ApexApiBill = {
  source: "apex",
  policyNumber: "en01",
  billStatus: "HAS_BILL",
  invoiceDueDate: "2099-01-01",
  invoiceDueAmount: 500,
  isAutoPay: true,
  paymentPlanPeriod: "ANNUAL",
  vehicles: ["1990 Chevrolet Impala"],
};

const apexBill: ApexApiBill = {
  source: "apex",
  policyNumber: "en01",
  billStatus: "HAS_BILL",
  invoiceDueDate: "2099-01-01",
  invoiceDueAmount: 500,
  isAutoPay: false,
  paymentPlanPeriod: "SEMIANNUAL",
  vehicles: ["1999 Chevrolet Impala"],
};

export const member: Partial<Member> = {
  __typename: "CustomerMember",
  id: "HDC00208785",
  assets: [
    {
      id: "02i8W000007oV9jQAE",
      status: AssetStatus.ACTIVE,
      affiliation: Affiliation.HAGERTY,
      amountPaid: {
        amount: 70,
      },
      activatedDate: "2022-06-18",
      effectiveAt: "2022-06-18",
      expireAt: "2023-06-18",
      omniOrderId: "1234",
      product: {
        id: "01t1I000003ATAFQA4",
        type: ProductType.MEMBERSHIP,
        code: "USMEMB2",
        price: {
          amount: 70,
        },
        isVIP: false,
        level: 2,
        benefits: [
          {
            __typename: "CustomerMemberRoadsideBenefit",
            included: true,
            towingDistance: 100,
            amountCovered: 100,
            vehicleType: VehicleType.CLASSIC,
          },
          {
            __typename: "CustomerMemberMagazineBenefit",
            included: true,
            issues: 6,
          },
          {
            __typename: "CustomerMemberHvtBenefit",
            included: true,
          },
          {
            __typename: "CustomerMemberEventsBenefit",
            included: true,
          },
        ],
      },
    },
  ],
};

const policyAddress = {
  Municipality: null,
  Country: "US",
  Township: null,
  Type: null,
  EffectiveDate: null,
  ExpirationDate: null,
  IsDefault: null,
  IsSeasonal: false,
  Id: 249740,
  LineOne: "385 GLENDALE ST",
  LineTwo: "",
  City: "Dakota",
  County: "Stephenson",
  StateRegion: "IL",
  PostalCode: "61018-9747",
};

const customers = [
  {
    Id: 7006,
    PartnerCustomerId: null,
    Name: {
      Prefix: "Mr.",
      First: "Mocked",
      Middle: "",
      Last: "Driver",
      Suffix: "",
    },
    DateOfBirth: "1964-03-01T00:00:00",
    CustomerType: "PRI",
    MaritalStatus: "P",
    Gender: "M",
    Relationship: "SELF",
    Email: "mockdriver@test.hagerty.com",
    Phone: {
      Id: 101221,
      AreaCode: "526",
      Prefix: "994",
      Suffix: "5813",
      Extension: null,
      Type: "HOME",
      EffectiveDate: "2004-01-01T00:00:00",
      ExpirationDate: null,
      IsDefault: true,
    },
    ResidenceAddress: {
      Country: "US",
      Township: null,
      Type: "HOME",
      EffectiveDate: "1999-03-02T00:00:00",
      ExpirationDate: null,
      IsDefault: false,
      IsSeasonal: false,
      Id: 8434958,
      LineOne: "385 GLENDALE ST",
      LineTwo: "",
      City: "Dakota",
      County: "Stephenson",
      StateRegion: "IL",
      PostalCode: "61018-9747",
    },
    MailingAddress: {
      Country: "US",
      Township: null,
      Type: "MAIL",
      EffectiveDate: "1999-03-02T00:00:00",
      ExpirationDate: null,
      IsDefault: true,
      IsSeasonal: false,
      Id: 9136448,
      LineOne: "385 GLENDALE ST",
      LineTwo: "",
      City: "Dakota",
      County: "Stephenson",
      StateRegion: "IL",
      PostalCode: "61018-9747",
    },
    DriverInfo: {
      DriversLicense:
        "AE+/hDrWgkGa7nHov4hz1gIAAABSP461U+ScKf8fR9/yoCdgUjdh1J8N+b+OlTRS6O3GzOf5W7+RS/4ZlBKgxg1uMSM=",
      LicensedState: "IL",
      IsVehicleOperator: true,
      YearsLicensed: 14,
      HasRegularUseVehicle: true,
      HasTickets: false,
      HasAccidentsOrClaims: false,
      IsLicensedLessThanThreeYearsIndValue: false,
      DateFirstLicensedMonth: null,
      DateFirstLicensedYear: null,
      MostUsedVehicle: null,
    },
    EntityName: "Adam Boland",
    InsuredType: "PRIM",
    OwnershipInterests: [],
  },
  {
    Id: 6690637,
    PartnerCustomerId: null,
    Name: {
      Prefix: "Ms.",
      First: "Mocked",
      Middle: "",
      Last: "Driver too",
      Suffix: null,
    },
    DateOfBirth: "1967-11-01T00:00:00",
    CustomerType: "SEC",
    MaritalStatus: "",
    Gender: "F",
    Relationship: "SPOUS",
    Email: null,
    Phone: null,
    ResidenceAddress: null,
    MailingAddress: null,
    DriverInfo: {
      DriversLicense:
        "AE+/hDrWgkGa7nHov4hz1gEAAAC/wYscMzVIhQ84ysJnfjGzX55ssZ9qkWIkpeA3boiwNPKbChf6OuZ2t0QQCWiPb0Q=",
      LicensedState: "IL",
      IsVehicleOperator: false,
      YearsLicensed: 0,
      HasRegularUseVehicle: true,
      HasTickets: false,
      HasAccidentsOrClaims: false,
      IsLicensedLessThanThreeYearsIndValue: false,
      DateFirstLicensedMonth: null,
      DateFirstLicensedYear: null,
      MostUsedVehicle: null,
    },
    EntityName: "Adam Boland",
    InsuredType: "SECD",
    OwnershipInterests: [],
  },
];

const jayLeno: Persona = {
  key: "jayLeno",
  description:
    "🇺🇸 | Multiple policies, one with broker, two enth+ | HDC 1.5 3rd Gear | All alerts",
  customer: {
    id: "7cf9a760-3167-40b4-a100-cd1590f76f66",
    email: "jay@leno.com",
    firstName: "Jay",
    middleName: "",
    lastName: "Leno",
    line1: "1149 Tower Road",
    line2: "",
    city: "Beverly Hills",
    state: "CA",
    postalCode: "90210",
    country: "USA",
    phone: "3025551212",
  },
  alerts: {
    hdcAutopayFailed: true,
    hdcBillPastDue: true,
    insuranceAutopayFailed: [
      {
        source: "core",
        policyNumber: "abc123",
        state: "MD",
        country: "USA",
      },
      {
        source: "apex",
        policyNumber: "en02",
        country: "USA",
      },
    ],
    insuranceBillPastDue: [
      {
        source: "core",
        policyNumber: "def456",
        state: "MD",
        country: "USA",
      },
      {
        source: "apex",
        policyNumber: "en01",
        country: "USA",
      },
    ],
    eSignDocuments: [
      {
        id: 1,
        source: "usa",
        state: "MD",
      },
    ],
  },
  contactId: "JAY123",
  coreBills: [hdcPastDueBill, ...coreBills],
  apexBills: [
    { ...apexBill, billStatus: "PAST_DUE" },
    { ...apexAutopayBill, policyNumber: "en02", billStatus: "FAILED" },
  ],
  insuranceWithBilling: {
    billing: { status: "success", amount: 123, dueDateISO: "2024-11-20" },
    policies: [
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "abc123",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        premium: 5000,
        finalPremium: 5000,
        state: "MD",
        country: "USA",
        broker: {
          name: "Mr Broker Man",
          phone: "0001112233",
          phoneExtension: "123",
          line1: "1000 Broker Ln",
          line2: "",
          city: "Baltimore",
          state: "MD",
          postalCode: "03456",
          email: "mr.broker@example.com",
        },
        vehicles: [
          {
            id: "100",
            year: 1965,
            make: "Shelby",
            model: "Cobra 427",
            value: 1000000,
            policyItemNumber: 1,
            vin: "1234567891",
          },
          {
            id: "101",
            year: 1968,
            make: "Dodge",
            model: "Super Bee",
            value: 65000,
            policyItemNumber: 2,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "def456",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        premium: 15000,
        finalPremium: 15000,
        state: "KS",
        country: "USA",
        vehicles: [
          {
            id: "200",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 48400000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "apex",
        hasIdCard: true,
        policyNumber: "en01",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        finalPremium: 25000,
        country: "USA",
        policyHolderName: "Jay Leno",
        vehicles: [
          {
            id: "0",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 48400000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
      },
      {
        source: "apex",
        hasIdCard: true,
        policyNumber: "en02",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        finalPremium: 15000,
        country: "USA",
        policyHolderName: "Jay Leno",
        vehicles: [
          {
            id: "0",
            year: 1965,
            make: "Ferrari",
            model: "350 GTO",
            value: 59500000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
      },
    ],
  },
  hdc: {
    hdcVersion: "1.5",
    membershipId: "HDC01981376",
    effectiveDate: "2020-01-20",
    expirationDate: "2021-01-20",
    benefits: { distance: 150, coversAllVehicles: true },
    membershipLevel: "3",
  },
  featureFlags: {},
  policyManagementDetails: {
    abc123: {
      source: "core",
      hasIdCard: true,
      policyNumber: "abc123",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      state: "MD",
      country: "USA",
      premium: 5000,
      finalPremium: 5000,
      vehicles: [
        {
          id: "100",
          year: 1965,
          make: "Shelby",
          model: "Cobra 427",
          value: 1000000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
        {
          id: "101",
          year: 1968,
          make: "Dodge",
          model: "Super Bee",
          value: 65000,
          policyItemNumber: 2,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: policyAddress,
      policyCoverages: [
        {
          Name: "Liability - CSL",
          Limit: "$300000",
          Type: 1,
          Premium: 69,
          PolicyCoverageId: 842533816,
          CoverageGroup: "CSL",
          CoverageParentGroup: "LIAB",
        },
        {
          Name: "Uninsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 6,
          Premium: 13,
          PolicyCoverageId: 842533818,
          CoverageGroup: "UM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Underinsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 7,
          Premium: 10,
          PolicyCoverageId: 842533819,
          CoverageGroup: "UIM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Medical Payments",
          Limit: "$5000",
          Type: 12,
          Premium: 8,
          PolicyCoverageId: 842533817,
          CoverageGroup: "MP",
          CoverageParentGroup: "MP",
        },
      ],
      broker: {
        name: "Mr Broker Man",
        phone: "0001112233",
        phoneExtension: "123",
        line1: "1000 Broker Ln",
        line2: "",
        city: "Baltimore",
        state: "MD",
        postalCode: "03456",
        email: "mr.broker@example.com",
      },
    },
    def456: {
      source: "core",
      hasIdCard: true,
      policyNumber: "def456",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      state: "KS",
      country: "USA",
      premium: 15000,
      finalPremium: 15000,
      vehicles: [
        {
          id: "200",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: policyAddress,
      policyCoverages: [
        {
          Name: "Liability - CSL",
          Limit: "$300000",
          Type: 1,
          Premium: 69,
          PolicyCoverageId: 842533816,
          CoverageGroup: "CSL",
          CoverageParentGroup: "LIAB",
        },
        {
          Name: "Uninsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 6,
          Premium: 13,
          PolicyCoverageId: 842533818,
          CoverageGroup: "UM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Underinsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 7,
          Premium: 10,
          PolicyCoverageId: 842533819,
          CoverageGroup: "UIM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Medical Payments",
          Limit: "$5000",
          Type: 12,
          Premium: 8,
          PolicyCoverageId: 842533817,
          CoverageGroup: "MP",
          CoverageParentGroup: "MP",
        },
      ],
    },
    en01: {
      source: "apex",
      hasIdCard: true,
      policyNumber: "en01",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      finalPremium: 15000,
      country: "USA",
      policyHolderName: "Jay Leno",
      vehicles: [
        {
          id: "0",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
    },
    en02: {
      source: "apex",
      hasIdCard: true,
      policyNumber: "en02",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      finalPremium: 15000,
      country: "USA",
      policyHolderName: "Jay Leno",
      vehicles: [
        {
          id: "0",
          year: 1965,
          make: "Ferrari",
          model: "350 GTO",
          value: 59500000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
    },
  },
};

const barackTrudeau: Persona = {
  key: "barackTrudeau",
  description:
    "🇺🇸/🇨🇦 | Multiple policies, one with broker | HDC 1.5 VIP | Insurance Autopay failed and HDC Autopay failed",
  customer: {
    id: "ef692b49-b390-4085-84ff-95f1f4e3a2fa",
    email: "barack@trudeau.com",
    firstName: "Barack",
    middleName: "",
    lastName: "Trudeau",
    line1: "1600 Pennsylvania Ave",
    line2: "",
    city: "Washington",
    state: "DC",
    postalCode: "01000",
    country: "USA",
    phone: "2023591000",
  },
  alerts: {
    hdcAutopayFailed: true,
    insuranceAutopayFailed: [
      {
        source: "core",
        policyNumber: "def456",
        state: "ON",
        country: "CANADA",
      },
    ],
  },
  contactId: "BARACK1",
  coreBills: [hdcAutopayFailedBill, ...coreBills],
  apexBills: [],
  insuranceWithBilling: {
    billing: { status: "success", amount: 35, dueDateISO: "2024-11-20" },
    policies: [
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "abc123",
        effectiveDate: "2025-12-25",
        expirationDate: "2026-11-30",
        premium: 5000,
        state: "DC",
        country: "USA",
        partner: "state-farm",
        broker: {
          name: "Mr Broker Man",
          phone: "0001112233",
          line1: "1000 Broker Ln",
          line2: "",
          city: "Baltimore",
          state: "MD",
          postalCode: "03456",
          email: "mr.broker@example.com",
        },
        vehicles: [
          {
            id: "100",
            year: 1965,
            make: "Shelby",
            model: "Cobra 427",
            value: 1000000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
          {
            id: "101",
            year: 1968,
            make: "Dodge",
            model: "Super Bee",
            value: 65000,
            policyItemNumber: 2,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "def456",
        effectiveDate: "2025-03-01",
        expirationDate: "2026-04-19",
        premium: 15000,
        state: "ON",
        country: "CANADA",
        vehicles: [
          {
            id: "200",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 48400000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
    ],
  },
  hdc: {
    hdcVersion: "1.5",
    membershipId: "HDC01981371",
    effectiveDate: "2020-01-20",
    expirationDate: undefined,
    benefits: { distance: 150, coversAllVehicles: true },
    membershipLevel: "VIP",
  },
  featureFlags: {},
  policyManagementDetails: {
    abc123: {
      source: "core",
      hasIdCard: true,
      policyNumber: "abc123",
      effectiveDate: "2025-12-25",
      expirationDate: "2026-11-30",
      state: "DC",
      country: "USA",
      premium: 5000,
      partner: "state-farm",
      vehicles: [
        {
          id: "100",
          year: 1965,
          make: "Shelby",
          model: "Cobra 427",
          value: 1000000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
        {
          id: "101",
          year: 1968,
          make: "Dodge",
          model: "Super Bee",
          value: 65000,
          policyItemNumber: 2,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: policyAddress,
      policyCoverages: [
        {
          Name: "Liability - CSL",
          Limit: "$300000",
          Type: 1,
          Premium: 69,
          PolicyCoverageId: 842533816,
          CoverageGroup: "CSL",
          CoverageParentGroup: "LIAB",
        },
        {
          Name: "Uninsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 6,
          Premium: 13,
          PolicyCoverageId: 842533818,
          CoverageGroup: "UM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Underinsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 7,
          Premium: 10,
          PolicyCoverageId: 842533819,
          CoverageGroup: "UIM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Medical Payments",
          Limit: "$5000",
          Type: 12,
          Premium: 8,
          PolicyCoverageId: 842533817,
          CoverageGroup: "MP",
          CoverageParentGroup: "MP",
        },
      ],
      broker: {
        name: "Mr Broker Man",
        phone: "0001112233",
        line1: "1000 Broker Ln",
        line2: "",
        city: "Baltimore",
        state: "MD",
        postalCode: "03456",
        email: "mr.broker@example.com",
      },
    },
    def456: {
      source: "core",
      hasIdCard: true,
      policyNumber: "def456",
      effectiveDate: "2025-03-01",
      expirationDate: "2026-04-19",
      state: "ON",
      country: "CANADA",
      premium: 15000,
      vehicles: [
        {
          id: "200",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: policyAddress,
      policyCoverages: [
        {
          Name: "Liability - CSL",
          Limit: "$300000",
          Type: 1,
          Premium: 69,
          PolicyCoverageId: 842533816,
          CoverageGroup: "CSL",
          CoverageParentGroup: "LIAB",
        },
        {
          Name: "Uninsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 6,
          Premium: 13,
          PolicyCoverageId: 842533818,
          CoverageGroup: "UM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Underinsured Motorists BI Single Limit",
          Limit: "$300000",
          Type: 7,
          Premium: 10,
          PolicyCoverageId: 842533819,
          CoverageGroup: "UIM",
          CoverageParentGroup: "UM",
        },
        {
          Name: "Medical Payments",
          Limit: "$5000",
          Type: 12,
          Premium: 8,
          PolicyCoverageId: 842533817,
          CoverageGroup: "MP",
          CoverageParentGroup: "MP",
        },
      ],
    },
  },
};

const janeDoe: Persona = {
  key: "janeDoe",
  description: "🇺🇸 | No policies | HDC 1.0 1st Gear",
  customer: {
    id: "270bc0f7-cc2f-49ac-8319-6a097568e13b",
    email: "jane@doe.com",
    firstName: "Jane",
    middleName: "Marie",
    lastName: "Doe",
    line1: "100 S. Main St",
    line2: "Apt 2B",
    city: "Motor City",
    state: "MI",
    postalCode: "40001-1234",
    country: "USA",
    phone: "2025551212",
  },
  alerts: {},
  contactId: "JANE123",
  coreBills: [],
  apexBills: [],
  insuranceWithBilling: {
    billing: { status: "success", amount: 0, dueDateISO: "2021-01-31" },
    policies: [],
  },
  hdc: {
    hdcVersion: "1.0",
    membershipId: "HDC04534125",
    expirationDate: "2022-04-27",
    effectiveDate: "2021-01-20",
    benefits: { distance: 20, coversAllVehicles: false },
    membershipLevel: "1",
  },
  featureFlags: {},
  policyManagementDetails: null,
};

const dukeCaboom: Persona = {
  key: "dukeCaboom",
  description:
    "🇨🇦 | Single policy with no broker | HDC 1.0 VIP | Bill past due",
  customer: {
    id: "f50cff93-7316-4193-a4de-9f27e1b647c1",
    email: "duke@caboom.com",
    firstName: "Duke",
    middleName: "",
    lastName: "Caboom",
    line1: "54 Erie Dr",
    line2: "",
    city: "Montreal",
    state: "QC",
    postalCode: "G1A 0A6",
    country: "CANADA",
    phone: "5145551212",
  },
  alerts: {
    insuranceBillPastDue: [
      {
        source: "core",
        policyNumber: "dc101",
        state: "QC",
        country: "CANADA",
      },
    ],
  },
  contactId: "DUKE123",
  coreBills,
  apexBills: [],
  insuranceWithBilling: {
    billing: { status: "unavailable" },
    policies: [
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "dc101",
        effectiveDate: "2021-12-25",
        expirationDate: "2022-11-30",
        premium: 51,
        state: "QC",
        country: "CANADA",
        vehicles: [
          {
            id: "100",
            year: 1936,
            make: "Indian",
            model: "Sport Scout Racer",
            value: 31250,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
        broker: {
          name: "Olde Insurance",
          phone: "5145551313",
          line1: "123 Main St",
          line2: "Suite 201",
          city: "Montreal",
          state: "QC",
          postalCode: "G1A 0A6",
        },
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
    ],
  },
  hdc: {
    hdcVersion: "1.0",
    membershipId: "HDC08923787",
    expirationDate: undefined,
    effectiveDate: "2022-06-20",
    benefits: { distance: 200, coversAllVehicles: true },
    membershipLevel: "VIP",
  },
  featureFlags: {},
  policyManagementDetails: {
    dc101: {
      source: "core",
      hasIdCard: true,
      policyNumber: "dc101",
      effectiveDate: "2021-12-25",
      expirationDate: "2022-11-30",
      premium: 51,
      state: "QC",
      country: "CANADA",
      vehicles: [
        {
          id: "100",
          year: 1936,
          make: "Indian",
          model: "Sport Scout Racer",
          value: 31250,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: {} as PolicyAddress,
      policyCoverages: [],
    },
  },
};

const jonHomeless: Persona = {
  key: "jonHomeless",
  description:
    "🇨🇦 | No address or phone | Multiple policies with a broker/billing error | No HDC | 2 E-sign Alerts",
  customer: {
    id: "b0dfb2d0-93f9-4dd6-8899-1a771127f98d",
    email: "jon@homeless.com",
    firstName: "Jon",
    middleName: "",
    lastName: "Homeless",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "CANADA",
    phone: "",
  },
  alerts: {
    eSignDocuments: [
      { id: 1, source: "usa", state: "MD" },
      { id: 2, source: "canada", state: "QC" },
    ],
  },
  contactId: "JON123",
  coreBills,
  apexBills: [apexBill],
  insuranceWithBilling: {
    billing: { status: "unavailable" },
    policies: [
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "abc123",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        premium: 5000,
        state: "MD",
        country: "USA",
        broker: {
          name: "Mr Broker Man",
          phone: "0001112233",
          line1: "1000 Broker Ln",
          line2: "",
          city: "Baltimore",
          state: "MD",
          postalCode: "03456",
          email: "mr.broker@example.com",
        },
        vehicles: [
          {
            id: "100",
            year: 1965,
            make: "Shelby",
            model: "Cobra 427",
            value: 1000000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
          {
            id: "101",
            year: 1968,
            make: "Dodge",
            model: "Super Bee",
            value: 65000,
            policyItemNumber: 2,
            vin: "1234567891",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "dc101",
        effectiveDate: "2021-12-25",
        expirationDate: "2022-11-30",
        premium: 51,
        state: "QC",
        country: "CANADA",
        vehicles: [
          {
            id: "100",
            year: 1936,
            make: "Indian",
            model: "Sport Scout Racer",
            value: 31250,
            policyItemNumber: 2,
            vin: "1234567890",
          },
        ],
        broker: {
          name: "Olde Insurance",
          phone: "5145551313",
          line1: "123 Main St",
          line2: "Suite 201",
          city: "Montreal",
          state: "QC",
          postalCode: "G1A 0A6",
        },
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "apex",
        hasIdCard: true,
        policyNumber: "en01",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        finalPremium: 15000,
        country: "USA",
        policyHolderName: "Jon Homeless",
        vehicles: [
          {
            id: "0",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 484,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
      },
    ],
  },
  hdc: null,
  featureFlags: {},
  policyManagementDetails: {
    abc123: {
      source: "core",
      hasIdCard: true,
      policyNumber: "abc123",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      premium: 5000,
      state: "MD",
      country: "USA",
      vehicles: [
        {
          id: "100",
          year: 1965,
          make: "Shelby",
          model: "Cobra 427",
          value: 1000000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
        {
          id: "101",
          year: 1968,
          make: "Dodge",
          model: "Super Bee",
          value: 65000,
          policyItemNumber: 2,
          vin: "1234567891",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: {} as PolicyAddress,
      policyCoverages: [],
      broker: {
        name: "Mr Broker Man",
        phone: "0001112233",
        line1: "1000 Broker Ln",
        line2: "",
        city: "Baltimore",
        state: "MD",
        postalCode: "03456",
        email: "mr.broker@example.com",
      },
    },
    dc101: {
      source: "core",
      hasIdCard: true,
      policyNumber: "dc101",
      effectiveDate: "2021-12-25",
      expirationDate: "2022-11-30",
      premium: 51,
      state: "QC",
      country: "CANADA",
      vehicles: [
        {
          id: "100",
          year: 1936,
          make: "Indian",
          model: "Sport Scout Racer",
          value: 31250,
          policyItemNumber: 2,
          vin: "1234567890",
        },
      ],
      broker: {
        name: "Olde Insurance",
        phone: "5145551313",
        line1: "123 Main St",
        line2: "Suite 201",
        city: "Montreal",
        state: "QC",
        postalCode: "G1A 0A6",
      },
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: {} as PolicyAddress,
      policyCoverages: [],
    },
    en01: {
      source: "apex",
      hasIdCard: true,
      policyNumber: "en01",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      finalPremium: 15000,
      country: "USA",
      policyHolderName: "Jay Leno",
      vehicles: [
        {
          id: "0",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
    },
  },
};

const markNobroker: Persona = {
  key: "markNobroker",
  description:
    "🇨🇦 | Multiple policies with no broker | HDC 1.0 AAA | ESign alert",
  customer: {
    id: "c57912ad-0bc7-49ac-a68e-2b8e5b3e3928",
    email: "mark@nobroker.com",
    firstName: "Mark",
    middleName: "",
    lastName: "Nobroker",
    line1: "54 Erie Dr",
    line2: "",
    city: "Montreal",
    state: "QC",
    postalCode: "G1A 0A6",
    country: "CANADA",
    phone: "5145551212",
  },
  alerts: {
    eSignDocuments: [{ id: 1, source: "canada", state: "QC" }],
  },
  contactId: "MARK123",
  coreBills,
  apexBills: [],
  insuranceWithBilling: {
    billing: { status: "unavailable" },
    policies: [
      {
        source: "core",
        hasIdCard: false,
        policyNumber: "dc101",
        effectiveDate: "2021-12-25",
        expirationDate: "2022-11-30",
        premium: 51,
        state: "QC",
        country: "CANADA",
        vehicles: [
          {
            id: "100",
            year: 1936,
            make: "Indian",
            model: "Sport Scout Racer",
            value: 31250,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
      {
        source: "core",
        hasIdCard: true,
        policyNumber: "def456",
        effectiveDate: "2025-03-01",
        expirationDate: "2026-04-19",
        premium: 15000,
        state: "QC",
        country: "CANADA",
        vehicles: [
          {
            id: "200",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 48400000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
        household: {} as Household,
        policyAddress: {} as PolicyAddress,
        policyCoverages: [],
      },
    ],
  },
  hdc: {
    hdcVersion: "1.0",
    membershipId: "HDC08923789",
    expirationDate: "2022-10-31",
    effectiveDate: "2021-10-31",
    benefits: undefined,
    membershipLevel: "AAA",
  },
  featureFlags: {},
  policyManagementDetails: {
    dc101: {
      source: "core",
      hasIdCard: false,
      policyNumber: "dc101",
      effectiveDate: "2021-12-25",
      expirationDate: "2022-11-30",
      premium: 51,
      state: "QC",
      country: "CANADA",
      vehicles: [
        {
          id: "100",
          year: 1936,
          make: "Indian",
          model: "Sport Scout Racer",
          value: 31250,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: {} as PolicyAddress,
      policyCoverages: [],
    },
    def456: {
      source: "core",
      hasIdCard: true,
      policyNumber: "def456",
      effectiveDate: "2025-03-01",
      expirationDate: "2026-04-19",
      premium: 15000,
      state: "QC",
      country: "CANADA",
      vehicles: [
        {
          id: "200",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
      household: {
        Customers: customers,
      } as unknown as Household,
      policyAddress: {} as PolicyAddress,
      policyCoverages: [],
    },
  },
};

const tomNoPolicies: Persona = {
  key: "tomNoPolicies",
  description: "🇨🇦 | No policies | No HDC | No alerts",
  customer: {
    id: "c01efaea-48ab-4d4b-b33f-e352118c0baf",
    email: "tom@nopolicies.com",
    firstName: "Tom",
    middleName: "",
    lastName: "NoPolicies",
    line1: "54 Erie Dr",
    line2: "",
    city: "Montreal",
    state: "QC",
    postalCode: "G1A 0A6",
    country: "CANADA",
    phone: "5145551212",
  },
  alerts: {},
  contactId: "TOM123",
  coreBills: [],
  apexBills: [],
  insuranceWithBilling: {
    billing: { status: "unavailable" },
    policies: [],
  },
  hdc: null,
  featureFlags: {},
  policyManagementDetails: null,
};

const lebron: Persona = {
  key: "lebron",
  description: "🇺🇸 | One enthusiast+ policy | HDC 1.5 3rd Gear",
  customer: {
    id: "96819923-1149-4dc6-9239-a93ef02ddadc",
    email: "lebron@nba.com",
    firstName: "LeBron",
    middleName: "",
    lastName: "James",
    line1: "123 king ln.",
    line2: "",
    city: "Cleveland",
    state: "OH",
    postalCode: "67349",
    country: "USA",
    phone: "3095554444",
  },
  alerts: {
    insuranceBillPastDue: [],
  },
  contactId: "BRON",
  coreBills: [],
  apexBills: [apexAutopayBill],
  insuranceWithBilling: {
    billing: { status: "success", amount: 123, dueDateISO: "2024-11-20" },
    policies: [
      {
        source: "apex",
        hasIdCard: true,
        policyNumber: "en01",
        effectiveDate: "1999-01-01",
        expirationDate: "2999-12-31",
        finalPremium: 15000,
        country: "USA",
        policyHolderName: "LeBron James",
        vehicles: [
          {
            id: "0",
            year: 1962,
            make: "Ferrari",
            model: "250 GTO",
            value: 48400000,
            policyItemNumber: 1,
            vin: "1234567890",
          },
        ],
      },
    ],
  },
  hdc: {
    hdcVersion: "1.5",
    membershipId: "HDC01981376",
    effectiveDate: "2020-01-20",
    expirationDate: "2021-01-20",
    benefits: { distance: 150, coversAllVehicles: true },
    membershipLevel: "3",
  },
  featureFlags: {},
  policyManagementDetails: {
    en01: {
      source: "apex",
      hasIdCard: true,
      policyNumber: "en01",
      effectiveDate: "1999-01-01",
      expirationDate: "2999-12-31",
      finalPremium: 15000,
      country: "USA",
      policyHolderName: "LeBron James",
      vehicles: [
        {
          id: "0",
          year: 1962,
          make: "Ferrari",
          model: "250 GTO",
          value: 48400000,
          policyItemNumber: 1,
          vin: "1234567890",
        },
      ],
    },
  },
};

export const personas = {
  jayLeno,
  janeDoe,
  dukeCaboom,
  jonHomeless,
  markNobroker,
  tomNoPolicies,
  barackTrudeau,
  lebron,
};

export type PersonaKey = keyof typeof personas;
export type Personas = { [key in PersonaKey]: Persona };
